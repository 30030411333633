<template>
  <div class="mobile-container">
    <div class="user-info-item img-cover">
      <img src="../../assets/images/website-bg.png" />
      <div class="back-item img-cover" @click="$router.go(-1)"><img src="../../assets/images/back-icon-black.png" /></div>
      <div class="user-info">
        <div class="header-item img-cover">
          <img v-if="userInfo.Header" :src="userInfo.Header" />
          <img v-else src="../../assets/images/default-header.png" />
        </div>
        <div class="info">
          <div class="name">
            <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
            <span v-else>{{ userInfo.Phone | phoneFormat }}</span>
          </div>
          <div class="level" :class="'level-' + userInfo.FzLevel">{{ userInfo.FName || '您还未创建分站' }}</div>
        </div>
        <router-link class="btn public-btn" :to="{path: '/buildSite'}">
          升级分站 <img src="../../assets/images/right-white-icon.png" />
        </router-link>
      </div>
    </div>
    <div class="page-content">
      <template v-if="websiteData.url && websiteData.url.length > 0">
        <div v-for="(item, index) in websiteData.url" :key="index" class="item">
          <div class="name">前端地址</div>
          <div class="value">{{ item.Url }}</div>
          <div class="btn" @click="handleCopy(item.Url)">复制</div>
        </div>
        <div class="item">
          <div class="name">后台地址</div>
          <div class="value">{{ websiteData.url[0].Url }}/admin.html</div>
          <div class="btn" @click="handleCopy(websiteData.url[0].Url + '/admin.html')">复制</div>
        </div>
      </template>
      <div class="item">
        <div class="name">管理员账号</div>
        <div class="value">{{ websiteData.admin_user }}</div>
      </div>
      <div class="item">
        <div class="name">管理员密码</div>
        <div class="value">******</div>
        <router-link class="btn" :to="{path: '/resetFzPwd'}">重置</router-link>
      </div>
      <div class="item">
        <div class="name">网站名称</div>
        <div class="value">{{ websiteData.site_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Website",
  data() {
    return {
      websiteData: {},
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.handleGetWebsiteInfo()
  },
  methods: {
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    async handleGetWebsiteInfo() {
      var res = await this.$API.substationsInfo.post()
      this.websiteData = res.info
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.user-info-item {
  width: 100%;
  height: 5.2rem;
  position: relative;
  .back-item {
    cursor: pointer;
    position: absolute;
    left: .14rem;
    top: .14rem;
    width: .6rem;
    height: .6rem;
  }
  .user-info {
    position: absolute;
    top: .88rem;
    left: 0;
    right: 0;
    padding: .2rem .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-item {
      width: 1.2rem;
      height: 1.2rem;
      border: .02rem solid #fff;
      border-radius: 50%;
      overflow: hidden;
    }
    .info {
      flex: 1;
      margin-left: .24rem;
      .name {
        line-height: .48rem;
        color: #11113a;
        font-weight: bold;
        font-size: .32rem;
      }
      .level {
        display: inline-block;
        margin-top: .08rem;
        height: .32rem;
        line-height: .32rem;
        padding: 0 .12rem;
        border-radius: .06rem;
        background-color: #fff;
        font-size: .2rem;
        color: #3f3f3f;
      }
    }
    .btn {
      width: 1.92rem;
      height: .64rem;
      border-radius: .32rem 0 0 .32rem;
      position: absolute;
      top: .48rem;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: .28rem;
      font-weight: 500;
      img {
        width: .2rem;
        height: .2rem;
        margin-left: .08rem;
      }
    }
  }
}
.page-content {
  margin: -2.4rem .24rem 0;
  overflow: hidden;
  position: relative;
  border-radius: .16rem;
  background-color: #fff;
  .item {
    margin: 0 .24rem;
    height: .96rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: .02rem solid #f8f8f8;
    font-size: .28rem;
    color: #242941;
    .name {
      width: 1.66rem;
    }
    .value {
      flex: 1;
    }
    .btn {
      cursor: pointer;
      margin-left: .24rem;
      color: #1399ff;
    }
  }
}
.level-50 {
  color: #3f3f3f !important;
  background: linear-gradient( 90deg, #ffffff 0%, #cbcbcb 100%);
  .label {
    background: linear-gradient( 121deg, #777678 0%, #3c3b3c 100%);
  }
}
.level-51 {
  color: #372506 !important;
  background: linear-gradient( 134deg, #fffdf8 0%, #ffc768 75%, #fff3e4 100%);
  .label {
    background-color: #f0c47f;
  }
}
.level-52 {
  color: #ebd29a !important;
  background: linear-gradient( 134deg, #221d11 0%, #0f0b08 75%, #403723 100%);
  .label {
    background: linear-gradient( 90deg, #94713b 0%, #c4a76a 100%);
  }
}
</style>